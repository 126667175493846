import { buildFullPath } from '.';

export const teacherAuthUrls = {
  login: buildFullPath('/t/auth/login'),
  clever: buildFullPath('/t/auth/login/clever'),
  classlink: buildFullPath('/t/auth/login/classlink'),
  signup: buildFullPath('/t/auth/signup'),
  resetPasswordResetEmail: buildFullPath('/t/auth/password/email'),
  resetPasswordTokenInvalid: buildFullPath('/t/auth/password/token/invalid'),
  resetPasswordTokenExpired: buildFullPath('/t/auth/password/token/expired'),
};

export const GO_URL = '/go';

export const publicUrls = {
  privacyPolicy: buildFullPath('/privacy-policy'),
  termsOfService: buildFullPath('/terms-of-service'),
  security: buildFullPath('/public/security'),
  updatedPolicies: buildFullPath('/public/updated-policies'),
  blocked: buildFullPath('/public/blocked'),
};
